import React from 'react'
import {FText, FView} from 'components'
import {colors} from 'styles'

export default function InputLabelBox({label, children, ...props}) {
  return (
    <FView {...props}>
      <FText h5 bold>
        {label}
      </FText>
      <FView size={5} />
      <FView bc={colors.lightGrey} bw={1} rounded justifyCenter>
        {children}
      </FView>
    </FView>
  )
}
