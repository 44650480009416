import React from 'react'
import {FView, FText} from 'components'
import {colors} from 'styles'

export default function Footer() {
  return (
    <FView w={'100vw'}>
      <FView h={1} bg={colors.grey25} />
      <FView h={60} center>
        <FText h6 center>
          © 2020 Foodly Technologies Inc.
        </FText>
      </FView>
    </FView>
  )
}
