import React from 'react'
import {Link} from 'react-router-dom'
import {FView, FText} from 'components'
import {colors} from 'styles'
import * as images from 'images'
import {useMediaMinMD} from 'f-web/src/hooks'

export default function NavBar() {
  const isMinMD = useMediaMinMD()
  return (
    <FView h={80} row justifyBetween ph={isMinMD ? 75 : 15} alignCenter shadow10>
      <Link to="/">
        <img style={{height: 40, objectFit: 'contain'}} src={images.foodlyLogo} alt="foodly logo" />
      </Link>
      <FView row h={50} alignCenter>
        <FText h7 color={colors.primary} bold>
          EN
        </FText>
        <FView size={4} />
        <FView selfStretch w={1} bg={colors.grey25} mh={25} />
        <FView size={4} />
        <FText h7 bold color={colors.lightGrey}>
          KR
        </FText>
      </FView>
    </FView>
  )
}
