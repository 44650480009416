export async function fFetch({url, method, ...restParams}) {
  const response = await fetch(url, {
    method: method || 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(restParams),
  })
  const text = await response.text()
  let json
  try {
    json = JSON.parse(text)
  } catch (e) {
    if (response.ok) {
      return text
    } else {
      throw new Error(text)
    }
  }
  if (response.ok) {
    return json
  } else {
    throw new Error(json.message)
  }
}

export const removeNonNumericString = str => {
  if (!str) {
    return ''
  }

  return str.replace(/\D+/g, '')
}
