import React, { Component } from 'react'
import { css } from 'emotion'
import { colors } from 'f-web/src/styles'

class FText extends Component {
  render() {
    const { children, style: customStyle, className: customClassName, onClick, ...props } = this.props

    // 1. Apply base style
    const style = [styles.baseStyle]

    // 2. Apply prop styles
    const keys = Object.keys(props)
    for (const key of keys) {
      if (props[key] === true && styles[key]) {
        style.push(styles[key])
      }

      switch (key) {
        case 'color':
          style.push(css({ color: props[key] }))
          break
        case 'fontSize':
          style.push(css({ fontSize: props[key] }))
          break
        default:
          break
      }
    }

    // 3. Apply customStyle passed down as this.props.style
    style.push(customStyle)
    style.push(customClassName)

    return (
      <span className={css(style)} onClick={onClick}>
        {children}
      </span>
    )
  }
}

const styles = {
  baseStyle: css({
    fontFamily: 'Montserrat',
  }),
  dark: css({
    color: colors.black,
  }),
  red: css({
    color: colors.red,
  }),
  grey: css({
    color: colors.darkGrey,
  }),
  white: css({
    color: colors.white,
  }),
  gold: css({
    color: colors.Gold,
  }),
  block: css({
    display: 'inline-block',
  }),
  disabled: css({
    color: 'rgba(255,255,255,0.6)',
  }),
  heading: css({
    fontFamily: 'futura',
  }),
  heading2: css({
    fontFamily: 'antonio',
  }),
  dafoe: css({
    fontFamily: 'dafoe',
  }),
  din: css({
    fontFamily: 'din',
  }),
  tech: css({
    fontFamily: 'tech',
  }),
  center: css({
    textAlign: 'center',
  }),
  right: css({
    textAlign: 'right',
  }),
  superhero: css({
    fontSize: 50,
  }),
  hero: css({
    fontSize: 40,
  }),
  h1: css({
    fontSize: 36,
  }),
  h2: css({
    fontSize: 24,
  }),
  h3: css({
    fontSize: 20,
  }),
  h4: css({
    fontSize: 18,
  }),
  h5: css({
    fontSize: 16,
  }),
  h6: css({
    fontSize: 14,
  }),
  h7: css({
    fontSize: 12,
  }),
  xBold: css({
    fontWeight: '900',
  }),
  bold: css({
    fontWeight: '700',
  }),
  thin: css({
    fontWeight: '300',
  }),
  xThin: css({
    fontWeight: '100',
  }),
}

export default FText
