export const COLOR = {
  // FOODLY THEME
  background: 'rgba(247, 247, 245, 1)',
  backgroundText: 'rgba(0, 0, 0, 1)',
  cardText: 'rgba(0, 0, 0, 1)',
  primaryText: 'rgba(255, 255, 255, 1)',

  white: 'rgba(255, 255, 255, 1)',
  white75: 'rgba(255, 255, 255, .75)',
  white50: 'rgba(255, 255, 255, .50)',
  white25: 'rgba(255, 255, 255, .25)',
  white10: 'rgba(255, 255, 255, .10)',

  black: 'rgba(0, 0, 0, 1)',
  black75: 'rgba(0, 0, 0, .75)',
  black50: 'rgba(0, 0, 0, .50)',
  black25: 'rgba(0, 0, 0, .25)',
  black10: 'rgba(0, 0, 0, .10)',

  lightGrey: 'rgba(151, 151, 151, 1)',
  lightGrey75: 'rgba(151, 151, 151, .75)',
  lightGrey50: 'rgba(151, 151, 151, .50)',
  lightGrey25: 'rgba(151, 151, 151, .25)',
  lightGrey10: 'rgba(151, 151, 151, .10)',

  grey: 'rgba(74, 74, 74, 1)',
  grey75: 'rgba(74, 74, 74, .75)',
  grey50: 'rgba(74, 74, 74, .50)',
  grey25: 'rgba(74, 74, 74, .25)',
  grey10: 'rgba(74, 74, 74, .10)',

  transparent: 'rgba(0, 0, 0, 0)',

  primary: 'rgba(236, 85, 31, 1)',
  primary75: 'rgba(236, 85, 31, .75)',
  primary50: 'rgba(236, 85, 31, .50)',
  primary25: 'rgba(236, 85, 31, .25)',
  primary10: 'rgba(236, 85, 31, .10)',

  secondary: 'rgba(254, 123, 76, 1)',
  secondary75: 'rgba(254, 123, 76, .75)',
  secondary50: 'rgba(254, 123, 76, .50)',
  secondary25: 'rgba(254, 123, 76, .25)',
  secondary10: 'rgba(254, 123, 76, .10)',

  success: 'rgba(33, 228, 150, 1)',
  success75: 'rgba(33, 228, 150, .75)',
  success50: 'rgba(33, 228, 150, .50)',
  success25: 'rgba(33, 228, 150, .25)',
  success10: 'rgba(33, 228, 150, .10)',

  warning: 'rgba(255, 186, 63, 1)',
  warning75: 'rgba(255, 186, 63, .75)',
  warning50: 'rgba(255, 186, 63, .50)',
  warning25: 'rgba(255, 186, 63, .25)',
  warning10: 'rgba(255, 186, 63, .10)',

  error: 'rgba(255, 56, 103, 1)',
  error75: 'rgba(255, 56, 103, .75)',
  error50: 'rgba(255, 56, 103, .50)',
  error25: 'rgba(255, 56, 103, .25)',
  error10: 'rgba(255, 56, 103, .10)',

  //Deprecated
  disabled: 'grey',
  mediumGrey: '#888888',
  darkGrey: '#4A4A4A',
  red: '#D0021B',
  Orange: '#FF9F00',
  Gold: '#936E4E',
  Green: '#BEE100',
  darkGreen: '#0FD05A',
}

export { COLOR as colors }

export const SCREEN = {
  // Mobile
  XXS: 320,
  XS: 375,
  SM: 425,

  // Tablet
  MD: 768,

  // Laptop
  LG: 1024,
  XL: 1440,

  // 4k
  XXL: 2560,
}

export const MEDIA_QUERY = {
  MIN_XXS: `@media (min-width: ${SCREEN.XXS}px)`,
  MIN_XS: `@media (min-width: ${SCREEN.XS}px)`,
  MIN_SM: `@media (min-width: ${SCREEN.SM}px)`,
  MIN_MD: `@media (min-width: ${SCREEN.MD}px)`,
  MIN_LG: `@media (min-width: ${SCREEN.LG}px)`,
  MIN_XL: `@media (min-width: ${SCREEN.XL}px)`,
  MIN_XXL: `@media (min-width: ${SCREEN.XXL}px)`,

  XXS: `@media (max-width: ${SCREEN.XXS}px)`,
  XS: `@media (max-width: ${SCREEN.XS}px)`,
  SM: `@media (max-width: ${SCREEN.SM}px)`,
  MD: `@media (max-width: ${SCREEN.MD}px)`,
  LG: `@media (max-width: ${SCREEN.LG}px)`,
  XL: `@media (max-width: ${SCREEN.XL}px)`,
  XXL: `@media (max-width: ${SCREEN.XXL}px)`,
}
