import React, { Component } from 'react'
import { css } from 'emotion'

class FButton extends Component {
  render() {
    const { className, animateOnHover, children, disabled, ...props } = this.props
    const classNames = css(classes.button, className, animateOnHover ? classes.animateOnHover : null)
    return (
      <button className={classNames} disabled={disabled} {...props}>
        {children}
      </button>
    )
  }
}

const classes = {
  button: css({
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    margin: 0,
    padding: 0,
    width: 'auto',
    overflow: 'visible',
    background: 'transparent',
    color: 'inherit',
    font: 'inherit',
    lineHeight: 'normal',
    textAlign: 'inherit',
    '-webkitTapHighlightColor': 'rgba(0,0,0,0)',
  }),
  animateOnHover: css({
    ':hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
}

export default FButton
