import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import * as pages from 'pages'

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={pages.Home.Home} />
        <Route path="/raffle/:raffleId" exact component={pages.Raffle.Raffle} />
      </Switch>
    </Router>
  )
}

export default Routes
