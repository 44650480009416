import React, {useState, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {FView, FText, NavBar, InputLabelBox, FInput, FButton, ButtonFillView, Footer} from 'components'
import {colors} from 'styles'
import * as images from 'images'
import Hero from './Hero'
import Label from './Label'
import {fFetch, removeNonNumericString} from 'utils'

const Home = () => {
  const [errorMessage, setErrorMessage] = useState()
  const [isEntering, setIsEntering] = useState(false)
  const emailInputRef = useRef()
  const phoneNumberInputRef = useRef()
  const history = useHistory()

  return (
    <FView>
      <NavBar />
      <Hero />
      <FView size={50} />
      <FView p={15} selfCenter w={400} maxWidth={'100vw'}>
        <img src={images.openedGift} alt="opened gift box" />
        <FView size={25} />
        <FText fontSize={34} bold center>
          Enter our raffle to win instant prizes!
        </FText>
        <FView size={15} />
        <FText h5 center>
          Enter our raffle by following the steps below for a chance to win amazing prizes including Amazon gift cards,
          foodly points, and free delivery coupons!
        </FText>
        <FView size={15} />
        <FView h={1} bg={colors.grey25} />
        <FView size={15} />
        <Label step={1} label="Create a Foodly Account" />
        <FText h5>Create an account on the Foodly mobile app.</FText>
        <FView size={4} />
        <FText h6>* Skip to step 2 if you already have a Foodly account.</FText>
        <FView size={10} />
        <FView row>
          <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/foodly-app/id1476138574">
            <FView animateOnHover>
              <img src={images.appStore} alt="appstore" />
            </FView>
          </a>
          <FView size={15} />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=tech.foodly.foodlymobile">
            <FView animateOnHover>
              <img src={images.googlePlay} alt="google play store" />
            </FView>
          </a>
        </FView>
        <FView size={25} />
        <Label step={2} label="Enter your contact information" />
        <InputLabelBox label="Email">
          <FInput ref={emailInputRef} type="email" placeholder="eric@example.com" />
        </InputLabelBox>
        <FView size={10} />
        <InputLabelBox label="Phone Number">
          <FInput ref={phoneNumberInputRef} type="tel" placeholder="(555) 555-5555" />
        </InputLabelBox>
        <FView size={25} />
        <Label step={3} label="Enter the raffle!" />
        <FText h5>That's it. Enter the raffle by clicking below:</FText>
        <FView size={10} />
        {!!errorMessage && (
          <FText color={colors.error} bold h4>
            {errorMessage}
          </FText>
        )}
        <FView size={10} />
        <FButton
          animateOnHover
          onClick={async () => {
            if (isEntering) {
              return
            }
            try {
              setIsEntering(true)
              const email = emailInputRef.current.value
              const phoneNumber = phoneNumberInputRef.current.value
              if (!email || !email.trim()) {
                setErrorMessage('Invalid Email')
                return
              }
              if (!phoneNumber || removeNonNumericString(phoneNumber).length !== 10) {
                setErrorMessage('Phone Number must be exactly 10 digits')
                return
              }
              const data = await fFetch({
                url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/enterRaffleDraw`,
                email: email.trim().toLowerCase(),
                phoneNumber: removeNonNumericString(phoneNumber),
              })
              if (data.raffleId) {
                history.push('/raffle/' + data.raffleId)
              } else {
                setErrorMessage(data.message || 'Unknown Error')
              }
            } catch (e) {
              setErrorMessage(e.message || 'Unknown Error')
            } finally {
              setIsEntering(false)
            }
          }}>
          <ButtonFillView rounded disabled={isEntering}>
            <FText h4 bold white>
              {isEntering ? 'Entering...' : 'Enter Raffle'}
            </FText>
          </ButtonFillView>
        </FButton>
      </FView>
      <FView size={100} />
      <Footer />
    </FView>
  )
}

export default Home
