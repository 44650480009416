export {default as foodlyLogo} from './foodlyLogo.png'
export {default as heroLeft} from './heroLeft.svg'
export {default as heroRight} from './heroRight.svg'
export {default as heroLeftSmall} from './heroLeftSmall.svg'
export {default as heroRightSmall} from './heroRightSmall.svg'
export {default as openedGift} from './openedGift.svg'
export {default as wheel} from './wheel.svg'
export {default as wheelStand} from './wheelStand.svg'
export {default as trophy} from './trophy.svg'

export const appStore = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good,w_130/app-store.png'
export const googlePlay = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good,w_130/google-play.png'
