import React from 'react'
import {FView, FText} from 'components'
import {colors} from 'styles'

export default function Label({step, label}) {
  return (
    <FView row alignCenter mb={15}>
      <FView br={15} size={30} bg={colors.primary} center>
        <FText h4 bold white>
          {step}
        </FText>
      </FView>
      <FView size={10} />
      <FText h3 bold>
        {label}
      </FText>
    </FView>
  )
}
