import React from 'react'
import {FView, FText} from 'components'
import {colors} from 'styles'
import {useMediaMinMD} from 'f-web/src/hooks'
import * as images from 'images'

const Hero = () => {
  const isMinMD = useMediaMinMD()
  return (
    <FView relative minHeight={250} maxHeight={420} h={'calc(16vw + 200px)'} bg={colors.secondary} center>
      <img
        src={isMinMD ? images.heroLeft : images.heroLeftSmall}
        style={{position: 'absolute', left: 0, top: 0, bottom: 0, height: '100%'}}
        alt="hero graffitis one"
      />
      <img
        src={isMinMD ? images.heroRight : images.heroRightSmall}
        style={{position: 'absolute', right: 0, top: 0, bottom: 0, height: '100%'}}
        alt="hero graffitis two"
      />
      <FView zIndex={1}>
        <FText white bold center fontSize={isMinMD ? 60 : 34}>
          Foodly Raffle
        </FText>
      </FView>
    </FView>
  )
}

export default Hero
