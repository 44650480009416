/** @jsx jsx */
import {jsx, css, keyframes} from '@emotion/core'
import {Link} from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react'
import {FView, FText, NavBar, Footer} from 'components'
import useReactRouter from 'use-react-router'
import {firestore} from 'config/firebase'
import {colors} from 'styles'
import * as images from 'images'

export default function Raffle({navigation}) {
  const {match} = useReactRouter()
  const {raffleId} = match.params
  const [raffleData, setRaffleData] = useState(null)
  const [isSpinning, setIsSpinning] = useState(true)
  const [errorMessage, setErrorMessage] = useState()
  useEffect(() => {
    firestore
      .doc(`/RafflesMarch2020/${raffleId}`)
      .get()
      .then(snapshot => {
        if (snapshot) {
          setRaffleData(snapshot.data())
        } else {
          setErrorMessage('Cannot retrieve raffle data')
        }
      })
      .catch(e => {
        setErrorMessage(e.message)
      })
  }, [raffleId])
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (raffleData) {
        setIsSpinning(false)
      } else {
        setErrorMessage('Cannot retrieve raffle data')
      }
    }, 11000)
    return () => clearTimeout(timeoutID)
  }, [raffleData])
  return (
    <FView minHeight={'100vh'}>
      <NavBar />
      {!!errorMessage && (
        <FView p={25}>
          <FText color={colors.error} bold h4 center>
            {errorMessage}
          </FText>
        </FView>
      )}
      <FView fill>
        <FView size={50} />
        <FView w={400} p={15} maxWidth="100vw" alignCenter selfCenter>
          {isSpinning ? (
            <>
              <FView relative w={266} h={276}>
                <img
                  src={images.wheelStand}
                  style={{position: 'absolute', zIndex: 1, bottom: 0, left: 0}}
                  alt="wheel stand"
                />
                <img
                  css={css`
                    animation: ${spin} 10s ease;
                  `}
                  src={images.wheel}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  }}
                  alt="wheel disk"
                />
              </FView>
              <FView size={25} />
              <FText h1 bold center>
                Spinning the Wheel
              </FText>
              <FView size={15} />
              <FText h5 center>
                We're spinning the wheel to see if your entry is a winning one!
              </FText>
            </>
          ) : (
            <>
              <img src={images.trophy} alt="trophy" />
              <FView size={25} />
              <FText h1 bold center>
                Congratulations!
              </FText>
              <FView size={15} />
              <FView>
                <FText h5>Congratulations! You've won the following:</FText>
                <FView size={10} />
                {raffleData.prizes.map(prize => {
                  return (
                    <FText key={prize} h5 bold>
                      • {prize}
                    </FText>
                  )
                })}
                <FView size={10} />
                <FText h5>
                  You have received Foodly points already. Other gifts will be given at the end of March after verifying
                  your phone number.
                </FText>
              </FView>
              <FView size={50} />
              <Link to="/">
                <FText color={colors.primary} bold h3>
                  {'< Back to Home'}
                </FText>
              </Link>
            </>
          )}
        </FView>
      </FView>
      <Footer />
    </FView>
  )
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(7200deg);
  }
`
